import { NgModule } from '@angular/core';
import {ExtraOptions, Router, RouterModule, Routes} from '@angular/router';
import { C3Routes } from '@features/cultural-creative-cities/cultural-creative-cities-routes';
import { CulturalCreativeCitiesGuard } from '@features/cultural-creative-cities/cultural-creative-cities.guard';
import { DataHubRoutes } from '@features/data-hub/data-hub-routes';
import { EuMimfRoutes } from '@features/eu-mimf/eu-mimf-routes';
import { GesMonitorRoutes } from '@features/ges-monitor/ges-monitor-routes';
import { HomeGuard } from '@features/home/guards/home.guard';
import { SocialScoreboardRoutes } from '@features/social-scoreboard/social-scoreboard-routes';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./features/home/home.module').then((m) => m.Module),
    },
    { path: 'index.jsp', redirectTo: '/' },
    {
        path: DataHubRoutes.MAIN_URL,
        data: {
            // breadcrumb: DataHubRoutes.MAIN_URL + '.page.main',
            breadcrumb: 'key_home',
        },
   //     canActivateChild: [DataHubGuard],
        loadChildren: () => import('./features/data-hub/data-hub.module').then((m) => m.Module),
    },
    {
        path: C3Routes.MAIN_URL,
        data: {
            // breadcrumb: C3Routes.MAIN_URL + '.page.main',
            breadcrumb: 'key_home',
        },
        canActivateChild: [CulturalCreativeCitiesGuard],
        loadChildren: () => import('./features/cultural-creative-cities/cultural-creative-cities.module').then((m) => m.Module),
    },
    {
        path: EuMimfRoutes.MAIN_URL,
        data: {
            // breadcrumb: EuMimfRoutes.MAIN_URL + '.page.main',
            breadcrumb: 'key_home',
        },
        loadChildren: () => import('./features/eu-mimf/eu-mimf.module').then((m) => m.Module),
    },
    {
        path: GesMonitorRoutes.MAIN_URL,
        data: {
            // breadcrumb: GesMonitorRoutes.MAIN_URL + '.page.main',
            breadcrumb: 'key_home',
        },
        loadChildren: () => import('./features/ges-monitor/ges-monitor.module').then((m) => m.Module),
    },
    {
        path: SocialScoreboardRoutes.MAIN_URL,
        data: {
            // breadcrumb: SocialScoreboardRoutes.MAIN_URL + '.page.main',
            breadcrumb: 'key_home',
        },
        loadChildren: () => import('./features/social-scoreboard/social-scoreboard.module').then((m) => m.Module),
    },
    { path: '*/**', redirectTo: '/' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, <ExtraOptions>{
            initialNavigation: 'enabledBlocking',
            anchorScrolling: 'enabled',
            scrollPositionRestoration: 'disabled',
            scrollOffset: [0, 150],
        }),
    ],
    providers: [
        HomeGuard,
        CulturalCreativeCitiesGuard,
    ],
})
export class AppRoutingModule {
    constructor(private router: Router) {
        this.router.errorHandler = (error: any) => {
            console.error(error);
            this.router.navigate(['/']);
        };
    }
}
